<script>
import { useToast } from "vue-toastification";
import { Modal } from "usemodal-vue3";
import { HalfCircleSpinner } from "epic-spinners";
import { useDonorsAuthStore } from "~/stores/donorsAuth.js";
import "~/assets/css/styles.css";
export default {
	data: () => ({
		menuCollapsed: false,
		choice: 0,
		feedback: null,
		feedback_loading: false,
	}),
	setup() {
		const toast = useToast();
		const donorsAuthStore = useDonorsAuthStore();
		return {
			toast,
			donorsAuthStore,
			feedbackModalOpened: ref(false),
		};
	},
	computed: {
		currentRouteName() {
			return this.$route.name;
		},
	},
	methods: {
		collapse() {
			this.menuCollapsed = !this.menuCollapsed;
		},
		sendFeedback() {
			this.feedback_loading = true;
			this.loading = true;
			donorsFetch("/feedback", {
				method: "POST",
				body: {
					level: this.choice,
					comment: this.feedback,
				},
			})
				.then((response) => {
					this.feedback_loading = false;
					this.feedbackModalOpened = false;
					this.choice = false;
					this.feedback = null;
					this.toast.success(response.message);
				})
				.catch((error) => {
					this.feedback_loading = false;
					this.toast.error(error, {
						timeout: 20000,
					});
				});
		},
	},
	components: {
		HalfCircleSpinner,
		Modal,
	},
};
</script>
<template>
	<div v-if="this.$device.isMobile">
		<div class="d-flex flex-column mobile-fixed-header">
			<div
				class="topnav navbar navbar-expand shadow justify-content-between justify-content-sm-start navbar-light bg-white position-relative">
				<div class="ml-2 h-75">
					<img src="/icon.png" class="h-100" />
				</div>
				<div class="navbar-brand ml-2">ALLODONS <sup>Donateurs</sup></div>
				<div class="navbar-nav align-items-center ms-auto text-black" @click="collapse">
					<i class="fa-solid fa-bars mr-4"></i>
				</div>
			</div>
			<div class="card card-collapsable">
				<div class="collapse" :class="[{ show: menuCollapsed }]" id="menu" style="">
					<div class="card-body">
						<NuxtLink to="/donors/home" @click="menuCollapsed = false">
							<div class="card border-start-lg mb-2" :class="[
									{ 'border-start-primary': currentRouteName == 'donors-home' },
								]">
								<div class="card-header">
									<div :class="[
											{
												'text-primary': currentRouteName == 'donors-home',
												'text-black-75': currentRouteName != 'donors-home',
											},
										]">
										<i class="fa-solid fa-id-card mr-2"></i> Ma fiche donateur
									</div>
								</div>
							</div>
						</NuxtLink>
						<NuxtLink to="/donors/donations" @click="menuCollapsed = false">
							<div class="card border-start-lg mb-2" :class="[
									{
										'border-start-primary':
											currentRouteName == 'donors-donations',
									},
								]">
								<div class="card-header">
									<div :class="[
											{
												'text-primary': currentRouteName == 'donors-donations',
												'text-black-75': currentRouteName != 'donors-donations',
											},
										]">
										<i class="fa-solid fa-donate mr-2"></i> Mes dons
									</div>
								</div>
							</div>
						</NuxtLink>
						<NuxtLink to="/donors/subscriptions" @click="menuCollapsed = false">
							<div class="card border-start-lg mb-2" :class="[
									{
										'border-start-primary':
											currentRouteName == 'donors-subscriptions',
									},
								]">
								<div class="card-header">
									<div :class="[
											{
												'text-primary':
													currentRouteName == 'donors-subscriptions',
												'text-black-75':
													currentRouteName != 'donors-subscriptions',
											},
										]">
										<i class="fa-solid fa-repeat mr-2"></i> Mes abonnements
									</div>
								</div>
							</div>
						</NuxtLink>
						<NuxtLink to="/donors/promises" @click="menuCollapsed = false">
							<div class="card border-start-lg mb-2" :class="[
									{
										'border-start-primary':
											currentRouteName == 'donors-promises',
									},
								]">
								<div class="card-header">
									<div :class="[
											{
												'text-primary': currentRouteName == 'donors-promises',
												'text-black-75': currentRouteName != 'donors-promises',
											},
										]">
										<i class="fa-solid fa-clock mr-2"></i> Mes promesses
									</div>
								</div>
							</div>
						</NuxtLink>
						<NuxtLink to="/donors/tasks" @click="menuCollapsed = false">
							<div class="card border-start-lg mb-2" :class="[
									{
										'border-start-primary': currentRouteName == 'donors-tasks',
									},
								]">
								<div class="card-header">
									<div :class="[
											{
												'text-primary': currentRouteName == 'donors-tasks',
												'text-black-75': currentRouteName != 'donors-tasks',
											},
										]">
										<i class="fa-solid fa-download mr-2"></i> Mes exports
									</div>
								</div>
							</div>
						</NuxtLink>
						<div class="card border-start-lg mb-2" :class="[
								{
									'border-start-primary':
										currentRouteName == 'organizations-tasks',
								},
							]" @click="feedbackModalOpened = true">
							<div class="card-header">
								<div class="text-info">
									<i class="fa-solid fa-comments mr-2"></i> Partagez votre avis
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<main class="mobile-body" v-show="!menuCollapsed">
			<div class="card">
				<slot />
				<div class="card-footer border-top-primary d-flex justify-content-between bg-white w-100"
					style="position: fixed; bottom: 0">
					<small class="font-weight-bold">{{ donorsAuthStore.donor }}</small>
					<small class="font-weight-bold text-primary pointer" @click="donorsAuthStore.logOut()">
						<i class="fa-solid fa-right-from-bracket"></i> Se déconnecter
					</small>
				</div>
			</div>
		</main>
	</div>
	<div v-else>
		<div
			class="topnav navbar navbar-expand shadow justify-content-between justify-content-sm-start navbar-light bg-white">
			<div class="order-1 order-lg-0 me-2 ms-lg-2 me-lg-0 h-75">
				<img src="/icon.png" class="h-100" />
			</div>
			<div class="navbar-brand pe-3 ps-4 ps-lg-2">
				ALLODONS <sup>Donateurs</sup>
			</div>
			<div class="navbar-nav align-items-center ms-auto text-black">
				<button class="btn btn-outline-info btn-sm mr-4 font-weight-bold" @click="feedbackModalOpened = true">
					<i class="fa-solid fa-comments mr-2"></i>Partagez votre avis
				</button>
			</div>
		</div>
		<div id="layoutSidenav">
			<div id="layoutSidenav_nav">
				<nav class="sidenav shadow-right sidenav-light">
					<div class="sidenav-menu">
						<div class="nav accordion" id="accordionSidenav">
							<NuxtLink class="nav-link" :class="[{ active: currentRouteName == 'donors-home' }]"
								to="/donors/home">
								<div class="nav-link-icon">
									<i class="fa-solid fa-id-card"></i>
								</div>
								Ma fiche donateur
							</NuxtLink>
							<NuxtLink class="nav-link" :class="[{ active: currentRouteName == 'donors-donations' }]"
								to="/donors/donations">
								<div class="nav-link-icon">
									<i class="fa-solid fa-donate"></i>
								</div>
								Mes dons
							</NuxtLink>
							<NuxtLink class="nav-link" :class="[
									{ active: currentRouteName == 'donors-subscriptions' },
								]" to="/donors/subscriptions">
								<div class="nav-link-icon">
									<i class="fa-solid fa-repeat"></i>
								</div>
								Mes abonnements
							</NuxtLink>
							<NuxtLink class="nav-link" :class="[{ active: currentRouteName == 'donors-promises' }]"
								to="/donors/promises">
								<div class="nav-link-icon">
									<i class="fa-solid fa-clock"></i>
								</div>
								Mes promesses
							</NuxtLink>
							<NuxtLink class="nav-link" :class="[{ active: currentRouteName == 'donors-tasks' }]"
								to="/donors/tasks">
								<div class="nav-link-icon">
									<i class="fa-solid fa-download"></i>
								</div>
								Mes exports
							</NuxtLink>
						</div>
					</div>
					<div class="sidenav-footer">
						<div class="sidenav-footer-content">
							<div class="sidenav-footer-title">
								{{ donorsAuthStore.donor }}
							</div>
							<div class="sidenav-footer-subtitle text-primary pointer" @click="donorsAuthStore.logOut()">
								<i class="fa-solid fa-right-from-bracket"></i> Se déconnecter
							</div>
						</div>
					</div>
				</nav>
			</div>
			<div id="layoutSidenav_content">
				<main>
					<slot />
				</main>
				<footer class="footer-admin mt-auto footer-light">
					<div class="container-xl px-4">
						<div class="row">
							<div class="col-md-6 small">Copyright &copy; ALLODONS SAS</div>
							<div class="col-md-6 text-md-end small">&middot;</div>
						</div>
					</div>
				</footer>
			</div>
		</div>
	</div>
	<Modal title="" modalClass="modal-custom" :type="'clean'" v-model:visible="feedbackModalOpened"
		:width="this.$device.isMobile ? '90%' : '30%'" :offsetTop="this.$device.isMobile ? '2%' : '5%'">
		<div class="card">
			<div class="card-header">
				<i class="fa-solid fa-comments mr-2"></i>
				Partager votre avis
			</div>
			<div class="card-body">
				<div class="d-flex justify-content-center" v-if="feedback_loading">
					<HalfCircleSpinner :animation-duration="1000" :size="30" color="#fa764b" />
				</div>
				<div v-else>
					Êtes-vous satisfait des services Allodons ?

					<div class="my-4 display-5 d-flex justify-content-between text-primary">
						<i :class="[choice == 1 ? 'fa-solid' : 'fa-regular']" class="fa-face-angry pointer"
							@click="choice = choice == 1 ? 0 : 1"></i>
						<i :class="[choice == 2 ? 'fa-solid' : 'fa-regular']"
							class="fa-regular fa-face-frown-open pointer" @click="choice = choice == 2 ? 0 : 2"></i>
						<i :class="[choice == 3 ? 'fa-solid' : 'fa-regular']" class="fa-regular fa-face-meh pointer"
							@click="choice = choice == 3 ? 0 : 3"></i>
						<i :class="[choice == 4 ? 'fa-solid' : 'fa-regular']" class="fa-regular fa-face-grin pointer"
							@click="choice = choice == 4 ? 0 : 4"></i>
						<i :class="[choice == 5 ? 'fa-solid' : 'fa-regular']"
							class="fa-regular fa-face-grin-beam pointer" @click="choice = choice == 5 ? 0 : 5"></i>
						<i :class="[choice == 6 ? 'fa-solid' : 'fa-regular']"
							class="fa-regular fa-face-grin-hearts pointer" @click="choice = choice == 6 ? 0 : 6"></i>
					</div>
					<div class="my-4 d-flex justify-content-between font-weight-bold">
						<sup class="text-danger">Pas du tout</sup>
						<sup class="text-success">Extrêmement</sup>
					</div>
					<div class="mb-3">
						<label class="font-weight-bold small mb-1">Vous pouvez nous partager en interne votre avis ou
							une suggestion
							:</label>
						<textarea class="form-control" v-model="feedback"></textarea>
					</div>
					<div class="mt-4 text-right">
						<button class="btn btn-sm btn-success font-weight-bold" type="button" @click="sendFeedback">
							<i class="fa-solid fa-paper-plane"></i>
							Partager
						</button>
					</div>
				</div>
			</div>
		</div>
	</Modal>
</template>
